<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Pending" subtitle="Approve pending assignments for specific slots and roles that require review." :readonly="$authorised('con/volunteers/access', 'read')">

		<app-content-head-actions-item text="Accept" icon="approve" :success="true" :disabled="!selected.length || is.rejecting" :loading="is.accepting" v-on:click="onAcceptClick" />
		<app-content-head-actions-item text="Reject" icon="reject.thumb" :danger="true" :disabled="!selected.length || is.accepting" :loading="is.rejecting" v-on:click="onRejectClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-checklist v-model="filter.roles" text="Role" tooltip="Select roles" :options="references.roles" />
		<app-content-filters-checklist v-model="filter.days" text="Day" tooltip="Select days" :options="references.days" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no pending assignments found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Submitted" />
				<app-table-column text="Role" />
				<app-table-column text="Badge" />
				<app-table-column text="Name" />
				<app-table-column text="Slot" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.date | formatDate('MM/DD/YYYY')" />
					<app-table-cell-text :text="item.role.name" />
					<app-table-cell-text :text="item.badge.reference" />
					<app-table-cell-text :text="item.badge.name" />
					<app-table-cell-text :text="item.slot.name" v-if="item.role.type === $constants.volunteers.role.type.schedule" />
					<app-table-cell-text :can-empty="true" v-if="item.role.type !== $constants.volunteers.role.type.schedule" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			is: {
				accepting: false,
				rejecting: false
			},
			references: {
				days: [],
				roles: []
			},
			filter: {
				search: '',
				days: [],
				roles: []
			},
			layout: '100px 150px 100px auto 250px 24px',
			endpoint: 'convention/volunteers/pending',
			live: 'convention/volunteers/pending'
		}

	},

	methods: {

		onEditClick: function(assignment) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignments.Edit',
				params: {
					id: assignment.id,
					role: assignment.role.id
				}
			})

		},

		onAcceptClick: function() {

			this.is.accepting = true

			this.$api.patch('convention/volunteers/pending', {
				index: this.selected,
				status: this.$constants.volunteers.assignment.status.confirmed
			}).then(function() {

				this.is.accepting = false

				this.$notify({
					message: 'The selected volunteers were accepted.'
				})

				this.loadRefresh()

			}.bind(this))

		},

		onRejectClick: function() {

			this.is.rejecting = true

			this.$api.patch('convention/volunteers/pending', {
				index: this.selected,
				status: this.$constants.volunteers.assignment.status.rejected
			}).then(function() {

				this.is.rejecting = false

				this.$notify({
					message: 'The selected volunteers were rejected.'
				})

				this.loadRefresh()

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>